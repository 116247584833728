import mastercard from '../../image/paymentMethods/card_mastercard.webp';
import visa from '../../image/paymentMethods/card_visa.webp';
import amex from '../../image/paymentMethods/card_american_express.webp';
import stripe from '../../image/paymentMethods/powered_by_stripe.webp';

var paymentIcons =  {}
                paymentIcons.mastercard = mastercard;
                paymentIcons.visa = visa;
                paymentIcons.amex = amex;
                paymentIcons.stripe = stripe;

export default paymentIcons;
