import React, { Component } from 'react';
import LanguageSwitcher from '../../containers/LanguageSwitcher';
import IntlMessages from '../../components/utility/intlMessages';
import { Menu } from 'antd';
//<Menu.Item key="1" onClick={handleLogout} >Sign In</Menu.Item>
export default class extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }
  render() {
    const { handleLogin, handleSignup, locale } = this.props;
    return (
      <Menu
        onClick={this.handleClick}
        style={{ width: 256 }}
        mode="inline"
      >
        <Menu.Item key="1"  onClick={handleLogin}><IntlMessages id="menu.login" /></Menu.Item>
        <Menu.Item key="2"  onClick={handleSignup}><IntlMessages id="menu.signup" /></Menu.Item>
        <Menu.Item key="3" ><IntlMessages id="menu.language" />: <LanguageSwitcher /></Menu.Item>
      </Menu>
    )
  }
}
