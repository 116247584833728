import antdFR from 'antd/es/locale/fr_FR';
import appLocaleData from 'react-intl/locale-data/fr';
import antdPicker from 'antd/es/date-picker/locale/fr_FR';
import saMessages from '../locales/fr_FR.json';

const FrLang = {
  messages: {
    ...saMessages
  },
  antd: antdFR,
  locale: 'fr-FR',
  data: appLocaleData,
  antdPicker: antdPicker
};
export default FrLang;
