import React, { Component } from 'react';
import notification from '../uielements/notification';
import InputQuantity from '../uielements/inputQuantity';
import TopbarCartWrapper from './singleCartModal.style';
import {
  DeleteOutlined
} from '@ant-design/icons';
import CurrencyLabels from "../../settings/currencies/labels";
export default class extends Component {
  onChange = value => {
    if (!isNaN(value)) {
      if (value !== this.props.quantity) {
        this.props.changeQuantity(this.props.objectID, value);
      }
    } else {
      notification('error', 'Please give valid number');
    }
  };

  render() {

/*
<InputNumber
  size='large'
  min={quantityMin}
  max={quantityMax}
  defaultValue={quant}
  style={{ width: '60px'}}
  onChange={(value) => this.onChange(value,id)}
  disabled={disabled} />
  */

    const {
      price,
      feePlatform,
      feesAdd,
      quant,
      objectID,
      cancelQuantity,
      category,
      title,
      quantityMin,
      quantityMax,
      currency,
      index,
      id,
      disabled
    } = this.props;
    let priceAdj;

    if (feesAdd) {
      priceAdj = (price + feePlatform).toFixed(2);
    } else {
      priceAdj = (price).toFixed(2);
    }

    let QuantObj = {};
    QuantObj.quantityDefault = quant;
    QuantObj.quantityMin = quantityMin;
    QuantObj.quantityMax = quantityMax;
    QuantObj.id = id;

    const totalPrice = (priceAdj * quant).toFixed(2);
    return (
      <TopbarCartWrapper className="cartItems">

        <div className="cartDetails">

          <div style={{  display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: 'center' }}>
            <div>
              <p>
                {category}
              </p>
            </div>
            <div>
              <a
                className="itemRemove"
                onClick={() => cancelQuantity(objectID)}
                href="#!"
              >
                <DeleteOutlined />
              </a>
            </div>
          </div>
          <div style={{  display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: 'center' }}>
            <div>
              <span>{CurrencyLabels[currency]}&nbsp;{priceAdj}</span>
            </div>
            <div>
              <span className="itemMultiplier">x</span>
            </div>
            <div>
              <InputQuantity onChange={this.onChange} values={QuantObj} disabled={false}/>
            </div>
            <div>
              <span className="itemPriceQuantity">{CurrencyLabels[currency]}&nbsp;{totalPrice}</span>
            </div>
          </div>
        </div>

      </TopbarCartWrapper>
    );
  }
}
