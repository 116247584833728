export function getView(width) {
  let newView = "MobileView";
  if (width > 991) {
    newView = "DesktopView";
  } else if (width > 479) {
    newView = "TabView";
  }
  return newView;
}
const actions = {

  TOGGLE_ALL: "TOGGLE_ALL",
  toggleAll: (width, height) => {
    const view = getView(width);
    return {
      type: actions.TOGGLE_ALL,
      view,
      height,
      width
    };
  },

};
export default actions;
