import React, { Component } from "react";
import { connect } from "react-redux";
import { ShoppingCartOutlined } from '@ant-design/icons';
import { Tooltip, Alert, Divider, Button, Popover, Badge, Typography } from "antd";
import IntlMessages from "../../components/utility/intlMessages";
import { FormattedMessage } from 'react-intl';
//import notification from '../../components/uielements/notification';
import Scrollbar from "../../components/utility/customScrollBar";
import SingleCart from "../../components/cart/singleCartModal";
import ecommerceAction from "../../redux/ecommerce/actions";
import CurrencyLabels from "../../settings/currencies/labels";
const { Title } = Typography;
const {
  changeViewTopbarCart,
  changeProductQuantity
} = ecommerceAction;
let totalPrice;
let disabled;
let currency;
let checkoutDisabled;
let checkoutDisabledLogin;

class TopbarAddtoCart extends Component {
  constructor(props) {
    super(props);
    this.handleVisibleChange = this.handleVisibleChange.bind(this);
    this.hide = this.hide.bind(this);
    this.renderProducts = this.renderProducts.bind(this);
    this.changeQuantity = this.changeQuantity.bind(this);
    this.cancelQuantity = this.cancelQuantity.bind(this);
    this.checkForCheckout = this.checkForCheckout.bind(this);
    this.state = {
      loginForCheckout: false,
      disabled: false
    };

  }
  hide() {
    this.props.changeViewTopbarCart(false);
  }
  handleVisibleChange() {
    this.props.changeViewTopbarCart(!this.props.viewTopbarCart);
  }


  checkForCheckout() {
    const { url, isLoggedIn, history, showLogin } = this.props;
    if (!isLoggedIn) {
      this.props.changeViewTopbarCart(!this.props.viewTopbarCart);
      showLogin();
      /*
      this.setState({ loginForCheckout: true });
      const update = () => {
      this.setState({ loginForCheckout: false });
      };
      setTimeout(update, 10000);
      //showLogin();*/
      return
    }
      this.props.history.push('/checkout');
      this.props.changeViewTopbarCart(!this.props.viewTopbarCart);
  }

  changeQuantity(objectID, quantity) {
    const { productQuantity } = this.props;
    const newProductQuantity = [];
    productQuantity.forEach(product => {
      if (product.objectID !== objectID) {
        newProductQuantity.push(product);
      } else {
        newProductQuantity.push({
          objectID,
          quantity
        });
      }
    });

    this.props.changeProductQuantity(newProductQuantity);
  }
  cancelQuantity(objectID) {
    const { productQuantity } = this.props;
    const newProductQuantity = [];
    productQuantity.forEach(product => {
      if (product.objectID !== objectID) {
        newProductQuantity.push(product);
      }
    });
    this.props.changeProductQuantity(newProductQuantity);
  }
  renderProducts() {
    const { productQuantity, products } = this.props;
    if (productQuantity && productQuantity.length > 0) {
      if (productQuantity[0].objectID === 'transfer') {
        disabled = true;
      } else {
        disabled = false;
      }
    }


    checkoutDisabled = true;
    totalPrice = 0;
    if (!productQuantity || productQuantity.length === 0) {
      return (
        <div className="isoNoItemMsg">
          <span><IntlMessages id="cart.empty" /></span>
        </div>
      );
    }


    return productQuantity.map((product,index) => {
      if (products[product.objectID].feesAdd) {
        totalPrice += product.quantity * ( products[product.objectID].price + products[product.objectID].feePlatform );
      } else {
        totalPrice += product.quantity * products[product.objectID].price;
      }

      currency = products[product.objectID].currency;
      checkoutDisabled = false;
      return (
        <div key={product.objectID}>
        {
          index === 0 &&
          <div>
            <h3 style={{  marginLeft: "0px", marginTop: "0px"}}>
              {products[product.objectID].title}
            </h3>
            <Divider  style={{  marginTop: "10px", paddingTop: "0px", marginBottom: "0px" }}/>
          </div>
        }

        <SingleCart
          key={product.objectID}
          index={index}
          objectID={product.objectID}
          quant={product.quantity}
          changeQuantity={this.changeQuantity}
          cancelQuantity={this.cancelQuantity}
          disabled={disabled}
          {...products[product.objectID]}
        />
        </div>
      );
    });
  }


  render() {
    const {
      url,
      productQuantity,
      viewTopbarCart,
      customizedTheme,
      prodcount,
      view
    } = this.props;
    const { loginForCheckout } = this.state;
    const content = (
      <div style={{ width: '100%', minWidth: '270px' }} className="topbarAddtoCart">
        <div  style={{ width: '100%',paddingTop: "10px"}} className="isoDropdownHeader">
          <Title level={4}><IntlMessages id="cart.title" /></Title>
          <Divider style={{ marginTop: "0px"}}/>
        </div>
        <div className="isoDropdownBody isoCartItemsWrapper">
          <Scrollbar style={{ height: 300 }}>{this.renderProducts()}</Scrollbar>
        </div>
        <div className="isoDropdownFooterLinks" style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }} >

            <Button
              type="primary"
              size="middle"
              onClick={this.checkForCheckout}
              disabled={checkoutDisabled}
              loading={this.state.checkoutLoading}
              >
              <IntlMessages id="cart.butCheckout" />
            </Button>

          <h3>
            <span>Total&nbsp;{CurrencyLabels[currency]}&nbsp;{totalPrice.toFixed(2)}</span>
          </h3>
        </div>

    {    loginForCheckout &&
        <div style={{  marginTop: "10px", width: "270px" }}>
          <Alert message={<IntlMessages id="cart.loginFirst" />} type="info" showIcon/>
        </div>
    }
  </div>
    );
    return (
      <div>
      { view == 'DesktopView' ? (
      <FormattedMessage id="cart.title">
      { msg => <Tooltip placement="bottom" title={msg}>
      <Popover
        content={content}
        trigger="click"
        visible={viewTopbarCart}
        onVisibleChange={this.handleVisibleChange}
        placement="bottomLeft"
      >
        <div>

          {prodcount === 0 ? (
            <ShoppingCartOutlined style={{ color: customizedTheme.textColor, fontSize: '30px' }} />
          ) : (

          <Badge count={prodcount}>
            <ShoppingCartOutlined style={{ color: customizedTheme.textColor, fontSize: '30px' }} />
          </Badge>

          )}
        </div>
      </Popover>
    </Tooltip>}
    </FormattedMessage>
    ):(
      <Popover
        content={content}
        trigger="click"
        visible={viewTopbarCart}
        onVisibleChange={this.handleVisibleChange}
        placement="bottomLeft"
      >
        <div>

          {prodcount === 0 ? (
            <ShoppingCartOutlined style={{ color: customizedTheme.textColor, fontSize: '30px' }} />
          ) : (

          <Badge count={prodcount}>
            <ShoppingCartOutlined style={{ color: customizedTheme.textColor, fontSize: '30px' }} />
          </Badge>

          )}
        </div>
      </Popover>
    )}
    </div>
    );
  }
}

/*
<Link to={`${url}/checkout`} onClick={this.hide}>
  <IntlMessages id="topbar.viewCart" />
</Link>
*/

function mapStateToProps(state) {
  return {
    ...state.Ecommerce,
    customizedTheme: state.Auth.topbarTheme,
    isLoggedIn: state.Auth.isLoggedInMember,
    prodcount: state.Ecommerce.productQuantity.length,
    view: state.App.view
  };
}
export default connect(mapStateToProps, {
  changeViewTopbarCart,
  changeProductQuantity
})(TopbarAddtoCart);
