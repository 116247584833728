import React, { Component } from 'react';
import { connect } from 'react-redux';
import { MenuOutlined } from '@ant-design/icons';
import {  Popover } from 'antd';
//import { PageHeader } from 'antd';
//import TopbarDropdownWrapper from './topbarDropdown.style';

import Menu from './menu';


class TopbarMenu extends Component {

  constructor(props) {
    super(props);
    this.handleVisibleChange = this.handleVisibleChange.bind(this);
    this.hide = this.hide.bind(this);
    this.handleLoginMenu = this.handleLoginMenu.bind(this);
    this.handleSignupMenu = this.handleSignupMenu.bind(this);
    this.state = {
      visible: false,

    };
  }



  hide() {
    this.setState({ visible: false });
  }
  handleVisibleChange() {
    this.setState({ visible: !this.state.visible });
  }

  handleLoginMenu() {
    this.setState({ visible: false });
    this.props.handleLogin();
  }

  handleSignupMenu() {
    this.setState({ visible: false });
    this.props.handleSignup();
  }

  render() {
  const { customizedTheme, locale } = this.props;


    const content = (
      <Menu
        handleLogin={this.handleLoginMenu}
        handleSignup={this.handleSignupMenu}
        locale={locale}
      />
    );

    return (
      <div>

      <Popover
        content={content}
        trigger="click"
        visible={this.state.visible}
        onVisibleChange={this.handleVisibleChange}
        arrowPointAtCenter={true}
        placement="bottomLeft"
      >
        <div style={{ paddingLeft: '5px' }}>
          <MenuOutlined style={{ color: customizedTheme.textColor, cursor: "pointer", fontSize: "25px", marginRight: "10px", marginTop: "25px" }} />
        </div>
      </Popover>
      </div>
    );
  }
}

function mapStateToProps(state) {
  //const { profilePicture } = state.Auth;
  return {
  //  user,

    customizedTheme: state.Auth.topbarTheme,

  };
}
export default connect(mapStateToProps,{
})(TopbarMenu);
