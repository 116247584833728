import FirebaseHelper from "../../helpers/firebase/index";
import { push } from 'react-router-redux';
import Cookies from 'universal-cookie';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import advanced from 'dayjs/plugin/advancedFormat';
dayjs.extend(timezone);
dayjs.extend(utc);
dayjs.extend(advanced);
const cookies = new Cookies();

const actions = {
  CHECK_AUTHORIZATION: 'CHECK_AUTHORIZATION',
  CHANGE_AVATAR: 'CHANGE_AVATAR',
  CHANGE_LOGO: 'CHANGE_LOGO',
  LOGOUT: 'LOGOUT',
  LOGIN_SUCCESS: 'LOGIN_SUCCESS',
  LOGIN_SUCCESS_MEMBER: 'LOGIN_SUCCESS_MEMBER',
  ADD_USER_CREDENTIALS: 'ADD_USER_CREDENTIALS',
  FETCH_USER: 'FETCH_USER',
  CHANGE_THEME: 'CHANGE_THEME',
  CHANGE_COUNTRY: 'CHANGE_COUNTRY',
  SET_COUNTRY: 'SET_COUNTRY',
  SET_REGION: 'SET_REGION',
  SET_STARTDATE: 'SET_STARTDATE',
  SET_ENDDATE: 'SET_ENDDATE',
  SET_SHOPMAP: 'SET_SHOPMAP',
  CHANGE_SELECTEDTYPE: 'CHANGE_SELECTEDTYPE',
  USER_ADDRESS: 'USER_ADDRESS',

  login: () => {
    let data = null;
    return function(dispatch) {
      dispatch({
        type: actions.LOGIN_SUCCESS,
        payload: { data },
      });

    };
  },

  loginMember: () => {
    let data = null;
    return function(dispatch) {
      dispatch({
        type: actions.LOGIN_SUCCESS_MEMBER,
        payload: { data },
      });

    };
  },

  changeAvatar: (url) => ({
    type: actions.CHANGE_AVATAR,
    url
  }),

  changeLogo: (url) => ({
    type: actions.CHANGE_LOGO,
    url
  }),

  changeCountry: (country) => ({
    type: actions.CHANGE_COUNTRY,
    country
  }),

  changeUserAddress: (userAddress) => ({
    type: actions.USER_ADDRESS,
    userAddress
  }),

  setCountry: () => {
  //  console.log('country triggered');
    return async function(dispatch) {
      let a = await cookies.get('country');
      if (a) {
        let country = a;
        dispatch({
        type: actions.SET_COUNTRY,
        country
        });
      } else {
      const response = await fetch(`https://europe-west1-cloudgate-35502.cloudfunctions.net/geolocationWebhook`)
        if (response.ok) {
         let result = await response.json();
         console.log('country', result.country,'ip', result.userIP,);
          dispatch({
            type: actions.SET_COUNTRY,
            country: result.country
          });
          } else {
           console.log("HTTP-Error: ",response.status);
          }
        }
        dispatch(actions.getRegion());
      }
  },

  setRegion: (region) => ({
    type: actions.SET_REGION,
    region
  }),

  getRegion: () => {
    return async function(dispatch, getState) {
//    const shopMap = getState().Auth.shopMap;
//    const country = getState().Auth.country;
    let region = await cookies.get('region');

  /*  let regionMax = '';
    let regionQuant = 0;
    let counter = 0;
    shopMap.map((co, index) => {
      if (co[0] === country) {
        co[2].map((re, index) => {
          if (co[3][index] > regionQuant) {
            regionMax = re;
          }
          if (re === region) {
            counter = 1;
          }
        });
      }
    });
    if (counter < 1) {
      region = regionMax || '';
    }*/
    if (region) {

    dispatch({
    type: actions.SET_REGION,
    region
    });

    };
    /* else {
      let region;
      dispatch({
      type: actions.SET_REGION,
      region
      });
    }*/

    };
  },

  changeSelectedType: (selectedType) => ({
    type: actions.CHANGE_SELECTEDTYPE,
    selectedType
  }),

  setStartDate: (dateStart) => ({
    type: actions.SET_STARTDATE,
    dateStart
  }),

  setEndDate: (dateEnd) => ({
    type: actions.SET_ENDDATE,
    dateEnd
  }),

  setDate: () => {
    return function(dispatch) {
      let weekStart = dayjs().startOf('week');
      let dateStart = weekStart.subtract('8', 'day');
    //  let dateEnd = weekStart.add('22','day');
      dateStart = dayjs(dateStart).diff(dayjs('2020-01-01T00:00:00').tz("UTC", true), "seconds");
      //dateEnd = dayjs(dateEnd).diff(dayjs('2020-01-01T00:00:00').tz("UTC", true), "seconds");
      //let selectedWeek = 3;
      dispatch(actions.setStartDate(dateStart));
      //dispatch(actions.setEndDate(dateEnd));
      //dispatch(actions.changeSelectedWeek(3));
    };
  },

  setShopMap: () => {
    return function(dispatch) {
    FirebaseHelper.firebaseGetDoc('shopMap', 'shopMap')
          .catch(err => {
           console.log('shopMap',err);
            })
          .then(doc => {
            if (doc.exists) {
              let data = doc.data();
            //  let countries = data.country;
              let shopMapRaw = data.region;
              //console.log('countries',countries,'regions',regions);
              //countries = Object.entries(countries);
              shopMapRaw = Object.entries(shopMapRaw);

          //    let countryArray = [];
          //    countries.map((country, index) => {
            //    if (country[1] > 0) {
            //      countryArray.push(country[0]);
            //    }
            //  });
              let shopMap = [];
              shopMapRaw.map((country, index) => {
                let counter = 0;
                let regions = Object.entries(country[1]);
                let helper = [];
                let helper2 = [];
                let helper3 = [];
                regions.map((region, index) => {
                  if (region[1] > 0) {
                    counter = counter + region[1];
                    helper2.push(region[0]);
                    helper3.push(region[1]);
                  }
                  if (regions.length - 1 === index) {
                    if (counter > 0) {
                      helper.push(country[0]);
                      helper.push(counter);
                      helper.push(helper2);
                      helper.push(helper3);
                      shopMap.push(helper);
                    }
                  }
                });
              });
          //    console.log('shopMap',shopMap);
          //    console.log('shopMapRedux', shopMap);
              dispatch({
                type: actions.SET_SHOPMAP,
                shopMap
              });
              //dispatch(actions.addUserCredentials('member'));
              dispatch(actions.setCountry());
              }
          });
   };
  },

  checkAuthorization: () => {
    return function(dispatch) {
    const userKey = Object.keys(sessionStorage)
    .filter(it => it.startsWith('firebase:authUser'))[0];
    const authuser = userKey ? JSON.parse(sessionStorage.getItem(userKey)) : undefined;
    dispatch(actions.setShopMap());
    dispatch(actions.setDate());

    if (authuser) {
      console.log('Authorisation:','auto');
    //dispatch(push('/loading'));

    FirebaseHelper.firebaseGetDoc('members', authuser.uid)
    .then(doc => {
      if (doc.exists) {

         let user = doc.data();
         let uid = doc.id;

         dispatch(actions.changeAvatar(authuser.photoURL || ''));
         dispatch(actions.changeUserAddress(user));
         dispatch(actions.addUserCredentials('member'));
         dispatch(actions.addUserCredentials(user.firstname));
         dispatch(actions.addUserCredentials(user.lastname));
         dispatch(actions.addUserCredentials(user.email));
         dispatch(actions.addUserCredentials('member'));
         dispatch(actions.addUserCredentials(uid));
         dispatch(actions.loginMember());

        // dispatch(push('/'));


      }
  });
      }
      else {

        console.log('Authorisation:','default');

        }
    }
  },

  logout: () => {
    return function(dispatch, getState) {
/*
dispatch({ type: actions.LOGOUT });
FirebaseHelper.logout();
*/
    FirebaseHelper.auth.signOut().then(() => {
      dispatch({ type: actions.LOGOUT });
    }).catch((error) => {
      console.log('LogOutError:',error.message);
    });
  }
  },

  logoutMember: () => {
    return function(dispatch) {

      dispatch({ type: actions.LOGOUT });
      FirebaseHelper.logout();
    }
  },

  addUserCredentials: (value) => ({
    type: actions.ADD_USER_CREDENTIALS,
    payload: value //payload: { data },
  }),

  fetchUser: () => ({
    type: actions.FETCH_USER
  }),
/*
  changeTheme: (attribute, themeName, docId) => {
    var a  = { [attribute]: themeName };
    FirebaseHelper.firebaseUpdateDocAttribute('companies', docId, a);

    const theme = getCurrentTheme(attribute, themeName);

    if (attribute === 'layoutTheme') {
    //  console.log('layoutTheme2',theme.backgroundColor);
      document.getElementsByClassName('isomorphicContent')[0].style.backgroundColor = theme.backgroundColor;
    }
    return {
      type: actions.CHANGE_THEME,
      attribute,
      theme
    };
  },

  changeThemeStart: (attribute, themeName) => {
    const theme = getCurrentTheme(attribute, themeName);

    if (attribute === 'layoutTheme') {
    //  console.log('layoutTheme2',theme.backgroundColor);
      document.getElementsByClassName('isomorphicContent')[0].style.backgroundColor = theme.backgroundColor;
    }
    return {
      type: actions.CHANGE_THEME,
      attribute,
      theme
    };
  }
*/
};
export default actions;
