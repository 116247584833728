
import Dayjs from 'dayjs';
let year = Dayjs().format('YYYY');

export default {
  apiUrl: 'https://clubrix.com/api/'
};

const siteConfig = {
  siteName: 'Clubrix',
  footerText: '© ' + year + ' by Cloud Gate, LLC'
};

const stripeConfig = {
  pk: 'pk_test_51Igj7UBKhET1XqyYuJ3qfk7HTLVgqq7dIAH7V7ob8OoVKrbDFA1k3s6dQneC2ngXdWxonGKSJJApCHzTlEfRf5f7005XzgR726',
};

const topbarTheme = {
buttonColor: '#4670a2',
backgroundColor: '#4670a2',
textColor: '#ffffff'
}

const sidebarTheme = {
buttonColor: '#2D3446',
backgroundColor: '#2D3446',
textColor: '#788195'
}

const layoutTheme = {
buttonColor: '#F1F3F6',
backgroundColor: '#F1F3F6',
textColor: '#323232'
}

const firebaseConfig = {
  apiKey: "AIzaSyD1_0h4urKGF4nNzrYMlvwVzup5AEy1aTE",
  authDomain: "auth.clubrix.com",
  projectId: "cloudgate-35502",
  storageBucket: "cloudgate-35502.appspot.com",
  appId: "1:50225868473:web:9decb03e18116cec1e3568"
};

const firebaseFunctions = {
  location: "europe-west1",
  projectId: "cloudgate-35502"
};

const googleConfig = {
  apiKey: 'AIzaSyDQ1qDLNzMd2SF4A4L69A5CjlIRTNvohsk' 
};

export {
  siteConfig,
  stripeConfig,
  topbarTheme,
  sidebarTheme,
  layoutTheme,
  firebaseConfig,
  firebaseFunctions,
  googleConfig
};
