import React, { Component, lazy, Suspense } from 'react';
import { connect } from 'react-redux';
import { ConfigProvider, Layout, Spin, Modal, Button, Tabs } from 'antd';
//import { SyncOutlined } from '@ant-design/icons';
import { IntlProvider } from 'react-intl';
import { Debounce } from 'react-throttle';
import WindowResizeListener from 'react-window-size-listener';
import { ThemeProvider } from 'styled-components';
import appActions from '../../redux/app/actions';
import TopbarShop from "../TopbarShop/topbarShop";
import TC from '../../components/terms/termsConditions';
import PP from '../../components/terms/privacyPolicy';
import { StripeProvider } from 'react-stripe-elements';
import pci from '../../image/pci.webp';
import AppShopRouter from './AppShopRouter';
import { siteConfig } from '../../settings';
import { stripeConfig } from '../../settings';
import AppLocale from "../../languageProvider";
import themes from '../../settings/themes';
import PaymentIcons from '../../settings/paymentMethods/icons';
const { TabPane } = Tabs;
const TopbarMember = lazy(() => import('../TopbarMember/topbarMember'));




const { Content, Footer } = Layout;
const { toggleAll } = appActions;

class AppShop extends Component {
  constructor(props) {
    super(props);
    this.showTC = this.showTC.bind(this);
    this.state = {
      stripe: null,
      tc: false,
    };
  }
  componentDidMount() {
    if (window.Stripe) {
      this.setState({stripe: window.Stripe(stripeConfig.pk)});
    } else {
      document.querySelector('#stripe-js').addEventListener('load', () => {
        // Create Stripe instance once Stripe.js loads
      this.setState({stripe: window.Stripe(stripeConfig.pk)});
      });
    }
  }

  showTC() {
    this.setState({ tc: !this.state.tc});
  }



  render() {
    const { history , isLoggedInMember, customizedTheme, locale, height, view } = this.props;
    const { url } = this.props.match;
    const { location } = this.props.location;
    const currentAppLocale = AppLocale[locale];
    const appHeight = window.innerHeight;

    /*
    { view !== "MobileView" &&
       <img height={40} width="auto" src={PaymentIcons.pci} style={{border: '1px solid #d8d8d8' , borderRadius: '3px', marginRight: '30px'}}/>
    }
    <img height={25} width="auto" src={PaymentIcons.sepa} style={{backgroundColor: '#ffffff', border: '1px solid #d8d8d8' , borderRadius: '3px', marginRight: '3px'}}/>
    <img height={25} width="auto" src={PaymentIcons.ideal} style={{backgroundColor: '#ffffff', border: '1px solid #d8d8d8' , borderRadius: '3px', marginRight: '3px', paddingLeft: '7px', paddingRight: '7px'}}/>
    <img height={25} width="auto" src={PaymentIcons.microsoft} style={{backgroundColor: '#ffffff',border: '1px solid #d8d8d8' , borderRadius: '3px', padding: '3px', marginRight: '3px'}}/>
    <img height={25} alt='' width="auto" src={PaymentIcons.google} style={{backgroundColor: '#ffffff', border: '1px solid #d8d8d8' , borderRadius: '3px', paddingTop: '4px', paddingBottom: '2px', marginRight: '3px'}}/>
    <img height={25} alt='' width="auto" src={PaymentIcons.apple} style={{backgroundColor: '#ffffff',border: '1px solid #d8d8d8' , borderRadius: '3px', marginRight: '3px'}}/>
    */

//<Spin style={{ marginTop: "15px" }}/>
    return (
      <ConfigProvider locale={currentAppLocale.antd}>
        <IntlProvider
          locale={currentAppLocale.locale}
          messages={currentAppLocale.messages}
        >
          <ThemeProvider theme={themes.themedefault}>
            <StripeProvider stripe={this.state.stripe}>
              <Layout style={{ height: appHeight }}>
                { view === 'Desktop' &&
                  <Debounce time="1000" handler="onResize">
                    <WindowResizeListener
                      onResize={windowSize =>
                        this.props.toggleAll(
                          windowSize.windowWidth,
                          windowSize.windowHeight
                        )
                      }
                    />
                  </Debounce>
                }

              {
                isLoggedInMember ? (
                <Suspense fallback={<div width='100%'  style={{ backgroundColor: customizedTheme.backgroundColor, height: 70 }}></div>}>
                  <TopbarMember url={url} view={view} history={history}/>
                </Suspense>
              ) : (

                  <TopbarShop showTC={this.showTC} url={url} view={view} history={history}/>

              )}
              <Layout style={{ flexDirection: 'row', overflowX: 'hidden' }}>
                <Layout

                  style={{
                    height: height
                  }}
                >
                  <Content

                    style={{
                      padding: '60px 0 0',
                      flexShrink: '0',
                      background: '#f1f3f6',
                      position: 'relative'
                    }}
                  >
                    <AppShopRouter url={url} location={location} isLoggedInMember={isLoggedInMember} history={history} />
                  </Content>
                  <Footer
                    style={view === "DesktopView" ? {
                      background: '#d9d9d9',
                      textAlign: 'center',
                      borderTop: '1px solid #d9d9d9',
                      fontSize: '12px',
                      height: '60px',
                      paddingTop: '3px',
                      paddingBottom: '0px'
                    }:{
                      background: '#d9d9d9',
                      textAlign: 'center',
                      borderTop: '1px solid #d9d9d9',
                      fontSize: '12px',
                      height: '60px',
                      paddingTop: '7px',
                      paddingBottom: '0px',
                      paddingLeft: '15px',
                      paddingRight: '10px'
                    }}
                  >
                  { view === "MobileView" ? (
                    <div style={{ display: "flex", flexDirection: "row", alignItems: 'center', justifyContent: "space-between" }}>
                      <div>
                        {siteConfig.footerText}
                      </div>
                      <div>
                        <img height={48} alt='pci' width="auto" src={pci} style={{border: '1px solid #d8d8d8' , borderRadius: '3px', marginRight: '3px'}}/>
                      </div>
                    </div>
                  ):(
                    <div style={{ display: "flex", flexDirection: "row", alignItems: 'center', justifyContent: "space-between" }}>
                      <div>
                        {siteConfig.footerText}&nbsp;&nbsp;|&nbsp;&nbsp;<span style={{cursor:'pointer'}} onClick={this.showTC} >Terms & Privacy</span>
                      </div>
                      <div>
                        <img height={56} alt='pci' width="auto" src={pci} style={{border: '1px solid #d8d8d8' , borderRadius: '3px', marginRight: '3px'}}/>
                      </div>

                    </div>
                 )}
                  </Footer>
                  <Modal

                    visible={this.state.tc}
                    closable={true}
                    onOk={this.showTC}
                    onClose={this.showTC}
                    onCancel={this.showTC}
                    footer={[
                      <Button type='primary' key="back" onClick={this.showTC}>
                        Ok
                      </Button>]}

                    width='800px'
                    zIndex='2000'
                    >
                    <div style={{  display: "flex",flexDirection: "column", justifyContent: "space-around", alignItems: 'center' }}>
                      <Tabs defaultActiveKey="1">
                        <TabPane tab="Terms and Conditions" key="1">
                          <TC/>
                        </TabPane>
                        <TabPane tab="Privacy Policy" key="2">
                          <PP/>
                        </TabPane>
                      </Tabs>
                    </div>
                  </Modal>
              </Layout>
            </Layout>
          </Layout>
        </StripeProvider>
      </ThemeProvider>
      </IntlProvider>
      </ConfigProvider>





    );
  }
}



export default connect(

  state => ({
        ...state.Auth,
        auth: state.Auth,
        locale: state.LanguageSwitcher.language.locale,
        isLoggedInMember: state.Auth.isLoggedInMember,
        customizedTheme: state.Auth.topbarTheme,
        height: state.App.height,
        view: state.App.view
  }),
  { toggleAll }
)(AppShop);
