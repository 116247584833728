import React, { Component } from 'react';
import { Route, Redirect } from 'react-router-dom';
import asyncComponent from '../../helpers/AsyncFunc';
//import ShopContent from "../ShopContent/shopContent";

const AuthRoute = ({ component: Component, isLoggedInMember, ...rest }) => (
  <Route
    {...rest}
    render={props => isLoggedInMember ? ( <Component {...props} />) : (<Redirect to={{
            pathname: "/",
            state: { from: props.location }
          }}
        />
      )
    }
  />
);

class AppShopRouter extends Component {


  render() {
    const { url, location, isLoggedInMember, history } = this.props;

    return (
      <div>
        <Route
          exact={true}
          key="shop"
          path={`${url}`}
          url={url}
          component={asyncComponent(() => import('../ShopContent/shopContent'))}
        />
        <Route
          exact={true}
          key="event"
          path={`${url}/event`}
          url={url}
          location={location}
          component={asyncComponent(() => import('../Event/event'))}
        />
        <Route
          exact={true}
          key="404"
          path={`${url}/404`}
          component={asyncComponent(() => import('../Page/404'))}
        />
        <Route
          exact={true}
          key="action"
          path={`${url}/action`}
          location={location}
          component={asyncComponent(() => import('../Page/action'))}
        />
        <AuthRoute
          exact={true}
          key="checkout"
          path={`${url}/checkout`}
          component={asyncComponent(() => import('../Ecommerce/checkout/checkout'))}
          isLoggedInMember={isLoggedInMember}
          history={history}
        />
      </div>
    );
  }
}

export default AppShopRouter
 //connect nicht erlaubt!!!!
