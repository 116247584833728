import google from '../../image/socialMedia/google.svg';
//import facebook from '../../image/socialMedia/facebook.png';
//import twitter from '../../image/socialMedia/twitter.png';
//import apple from '../../image/socialMedia/apple.png';
var socialMediaIcons =  {}
                socialMediaIcons.google = google;
        //        socialMediaIcons.facebook = facebook;
        //        socialMediaIcons.twitter = twitter;
        //        socialMediaIcons.apple = apple;


export default socialMediaIcons;
