import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { Input, Checkbox, Divider, Row, Col, Avatar, Button, Select } from 'antd';
import { MailOutlined, GoogleOutlined, AppleOutlined } from '@ant-design/icons';
import authAction from '../../redux/auth/actions';
import appActions from '../../redux/app/actions';
import FirebaseHelper from "../../helpers/firebase/index";
import notification from '../../components/uielements/notification';
import notificationIntl from '../../components/uielements/notificationIntl';
import { FormattedMessage,  injectIntl } from 'react-intl';
import IntlMessages from '../../components/utility/intlMessages';
import SocialMediaIcons from '../../settings/socialMedia/icons';
import Countries from '../../settings/countries/forms';
const { loginMember , logoutMember, addUserCredentials, changeAvatar, changeUserAddress } = authAction;
const { clearMenu } = appActions;
const { Option } = Select;

class SignUp extends Component {
  constructor(props){
    super(props);
    this.triggerSignin = this.triggerSignin.bind(this);
    this.handleRegister = this.handleRegister.bind(this);
    this.singleOption = this.singleOption.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.acceptTC = this.acceptTC.bind(this);
    //console.log('ipcountry', this.props.ipCountry);
    let def = {};
    Countries.map(data => {
      if (this.props.ipCountry == data.code){
        def = data;
      }
    });
    this.state = {
      buttdis: false,
      firstname:'',
      lastname:'',
      address:'',
      address2:'',
      city:'',
      zip:'',
      state: '',
      country: def.name,
      countryFlag: def.flag,
      countryCode: def.code,
      email:'',
      phone:'',
      cphone: def.dial_code,
      password:'',
      password2:'',
      checkbox: false,
      socialMedia: false,
      showPassword: false,
     }
    }




  handleRegister(provider) {
  let res;
      //const { closeSignup } = this.props;
      const { formatMessage } = this.props.intl;
      const  { checkbox , firstname , lastname , address , city , zip , state, country , countryCode, email , phone, password , password2, showPassword } = this.state;

      if ( checkbox === false ) {
        //notification('error', 'You have to accept the terms and conditions.');
        notificationIntl('error','not.termsAccept',formatMessage);
        return;
        }
      if (!(firstname && lastname && address && city && zip && country)) {
        //notification('error', 'Please fill out all required fields.');
        notificationIntl('error','not.fillAllFields',formatMessage);
        return;
        }
    if (provider == 'password') {
      if (showPassword == false) {
        this.setState({ showPassword: true });
        //notification('info', 'Please define email and password.');
        notificationIntl('error','not.emailAndPassword',formatMessage);
        return;
      }
      if (!email) {
        notificationIntl('error','not.emailAndPassword',formatMessage);
        return;
      }
      function validateEmail(email) {
        const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
       }
      if (validateEmail(email) == false) {
        //notification('error', 'Please provide a valid email address.');
        notificationIntl('error','not.validEmail',formatMessage);
        return;
      }
      if (!(password && password2)) {
        //notification('error', 'Please define a password.');
        notificationIntl('error','not.confirmPassword',formatMessage);
        return;
      }
      if (password != password2) {
        //notification('error', 'Passwords are different.');
        notificationIntl('error','not.unequalPassword',formatMessage);
        return;
      }
    } else { this.setState({ showPassword: false});}

    this.setState({ buttdis: true });
    (async() => {

        const { checkbox , firstname , lastname , address , address2, city , zip , state, country, countryCode, email , phone, cphone, password, buttdis } = this.state;
        const { locale } = this.props;
              const capitalize = (s) => {
                if (typeof s !== 'string') return ''
                return s.charAt(0).toUpperCase() + s.slice(1)
              }

              const member= {};
              member.firstname = capitalize(firstname);
              member.lastname = capitalize(lastname);
              member.address = capitalize(address);
              member.address2 = capitalize(address2) || '';
              member.city = capitalize(city);
              member.zip = zip;
              member.state = state;
              member.country = capitalize(country);
              member.countryCode = countryCode;
              member.email = email;
              member.phone = phone;
              member.phonePre = cphone;
              member.avatar = '';
              member.pm = []
              member.created_at = new Date().getTime();
              let c = ['en'];
              if (locale != 'en') {
                c = [ locale ,'en'];
              }
              const a = member.firstname + ' ' + member.lastname;
              const b = {
               "address": {
                  "line1": member.address,
                  "line2": member.address2 || '',
                  "city": member.city,
                  "postal_code": member.zip,
                  "state": member.state || '',
                  "country": member.country
               },
               "shipping": {
                  "address": {
                    "line1": member.address,
                    "line2": member.address2 || '',
                    "city": member.city,
                    "postal_code": member.zip,
                    "state": member.state || '',
                    "country": member.country
                  },
                  "name": a,
                  "phone": member.phonePre + ' ' + member.phone,
               },
               "email": member.email,
               "metadata": '',
               "name": a,
               "phone": member.phonePre + ' ' + member.phone,
               "preferred_locales": c,
               };
        if (provider == 'password') {
            res = await FirebaseHelper.firebaseCreateUser(email,password)
                .then(result => {
                  result.user.updateProfile({ displayName: a });
                  //if (result) {
                    const uid = result.user.uid;
                    const c = { "member": member, "a": b, "uid": uid, "type": "password" };
                  //  console.log('uid',uid,'payload',c);
                    return { payload: c };
                //  }
                })
                .catch(result => {
                  const message = result.message
                  notification('error', message);
                  this.setState({ buttdis: false });
                  return;
                });
        }
        if (provider == 'google') {
          this.setState({socialMedia: true });
          const { locale } = this.props;
      //    console.log('google');

          var prov = FirebaseHelper.googleAuthProvider();
          res = await FirebaseHelper.firebaseLoginProvider(prov,locale).then(function(result) {
            var user = result.user;
        //    console.log('result.user',result);
            const uid = result.user.uid;
            member.email = result.user.email;
            member.avatar = result.user.photoURL;
            b.email = result.user.email;
            const c = { "member": member, "a": b, "uid": uid, "type": "google" };
        //    console.log('uid',uid,'payload',c);
        //    return { uid: uid, payload: c };
              return { payload: c };
          }).catch(function(error) {
            // Handle Errors here.
            var errorCode = error.code;
            var errorMessage = error.message;
            notification('error', errorMessage);
            this.setState({socialMedia: false });
            this.setState({buttdis: false });
            return;
            // The email of the user's account used.
            var email = error.email;
            // The firebase.auth.AuthCredential type that was used.
            var credential = error.credential;
            // ...
          }.bind(this));
        }
        if (res) {

            FirebaseHelper.httpCallable('HttpCallableCreateMember',res)
            .catch((error) => {
              var code = error.code;
              var message = error.message;
              var details = error.details;
              console.log('error',code);
              console.log('message',message);
              console.log('details',details);
            })
            .then((result) => {

              if (res.payload.type === 'password') {

                   FirebaseHelper.sendEmailVerification(this.props.locale)
                   .then(function() {
                    // Email Verification sent!
                    this.props.logoutMember();
                    this.props.closeSignup();
                    notificationIntl('info','action.sendEmailVerification',formatMessage);
                    this.setState({ buttdis: false });
                    return;
                    }.bind(this));
               }

    // Read result of the Cloud Function.
            if (res.payload.type == 'google') {

        //      console.log('result',result);
        //      console.log(result.data.uid,res.payload.uid);

/*
                let a={};
                a.firstname = res.payload.member.firstname;
                a.lastname = res.payload.member.lastname;
                a.address = res.payload.member.address;
                a.address2 = res.payload.member.address2 || '';
                a.zip = res.payload.member.zip;
                a.city = res.payload.member.city;
                a.state = res.payload.member.state || '';
                a.country = res.payload.member.country;
                a.countryCode = res.payload.member.countryCode;
                a.phone = res.payload.member.phone || '';
                a.phonePre = res.payload.member.phonePre || '';
                a.email = res.payload.member.email;
*/
                this.props.changeAvatar(res.payload.member.avatar || '');
                this.props.changeUserAddress(res.payload.member);
                this.props.addUserCredentials('member');
                this.props.addUserCredentials(res.payload.member.firstname);
                this.props.addUserCredentials(res.payload.member.lastname);
                this.props.addUserCredentials(res.payload.member.email);
                this.props.addUserCredentials('member');
                this.props.addUserCredentials(res.payload.uid);
                notification('success', 'User registered');
                this.setState({buttdis: false });
                this.props.loginMember();


           }
          });
        }
    })();

}

  triggerSignin() {
    this.props.handleLogin();
  }

  singleOption(valueObj,index) {
    return (<Option key={index} value={index }>{valueObj.flag}&nbsp;&nbsp;{valueObj.name}</Option>);
    }

  handleChange(value) {
    this.setState({ country: Countries[value].name });
    this.setState({ cphone: Countries[value].dial_code });
    this.setState({ countryFlag: Countries[value].flag });
    this.setState({ countryCode: Countries[value].code });
    }

    acceptTC() {
      this.props.showTC();
    }

  render() {
    const { history, logoDark } = this.props;
    const { showPassword } = this.state;

    return (
         <div>
            <div>
              <img height={40} src={logoDark} />
            </div>
            <Divider   style={{ marginTop: "15px", marginBottom: "15px" }}/>
            <Row gutter={16}>
              <Col style={{ marginBottom: "5px" }} xs={24} sm={12} md={12} lg={12} xl={12}>
                <FormattedMessage id="forms.firstname">
                { msg => <Input  type="text" size="large" placeholder={msg} disabled={this.state.buttdis} value={this.state.firstname} onChange={event => this.setState({firstname: event.target.value})}/>}
              </FormattedMessage>
              </Col>
              <Col style={{ marginBottom: "5px" }} xs={24} sm={12} md={12} lg={12} xl={12}>
                <FormattedMessage id="forms.lastname">
                { msg => <Input  type="text" size="large" placeholder={msg} disabled={this.state.buttdis} value={this.state.lastname} onChange={event => this.setState({lastname: event.target.value})}/>}
              </FormattedMessage>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col style={{ marginBottom: "5px" }} xs={24} sm={12} md={12} lg={12} xl={12}>
                <FormattedMessage id="forms.address">
                { msg => <Input  type="text" size="large" placeholder={msg} disabled={this.state.buttdis} value={this.state.address} onChange={event => this.setState({address: event.target.value})}/>}
              </FormattedMessage>
              </Col>
              <Col style={{ marginBottom: "5px" }} xs={24} sm={12} md={12} lg={12} xl={12}>
                <FormattedMessage id="forms.address2">
                { msg => <Input  type="text" size="large" placeholder={msg} disabled={this.state.buttdis} value={this.state.address2} onChange={event => this.setState({address2: event.target.value})}/>}
              </FormattedMessage>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col style={{ marginBottom: "5px" }} xs={24} sm={12} md={12} lg={12} xl={12}>
                <FormattedMessage type="number" id="forms.zip">
                { msg => <Input  size="large" placeholder={msg} disabled={this.state.buttdis} value={this.state.zip} onChange={event => this.setState({zip: event.target.value})}/>}
              </FormattedMessage>
              </Col>
              <Col style={{ marginBottom: "5px" }} xs={24} sm={12} md={12} lg={12} xl={12}>
                <FormattedMessage id="forms.city">
                { msg => <Input type="text" size="large" placeholder={msg}  disabled={this.state.buttdis}value={this.state.city} onChange={event => this.setState({city: event.target.value})}/>}
              </FormattedMessage>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col style={{ marginBottom: "5px" }} xs={24} sm={12} md={12} lg={12} xl={12}>
                <FormattedMessage id="forms.state">
                { msg => <Input type="state"  size="large" placeholder={msg} disabled={this.state.buttdis} value={this.state.state} onChange={event => this.setState({state: event.target.value})}/>}
                </FormattedMessage>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col style={{ marginTop: "5px" }} xs={24} sm={12} md={12} lg={12} xl={12}>
                <Select
                  size="large"
                  defaultValue={this.state.countryFlag + '  ' + this.state.country}
                  defaultActiveFirstOption={false}
                  style={{ width: '100%' }}
                  onChange={this.handleChange}
                  disabled={this.state.buttdis}
                  >
                  {Countries.map((country, index) => this.singleOption(country, index))}
                </Select>
              </Col>
              <Col style={{ marginTop: "5px" }} xs={24} sm={12} md={12} lg={12} xl={12}>
                <Input.Group size="large">
                  <Row gutter={1}>
                    <Col span={8}>
                      <FormattedMessage id="forms.phonePre">
                      { msg => <Input size="large"
                        type="tel"
                        disabled={this.state.buttdis}
                        placeholder={msg}
                        value={this.state.cphone}
                        onChange={event => this.setState({cphone: event.target.value})}
                        />}
                      </FormattedMessage>
                    </Col>
                    <Col span={16}>
                      <FormattedMessage id="forms.mobile">
                      { msg => <Input type="tel" size="large" placeholder={msg} disabled={this.state.buttdis} value={this.state.phone} onChange={event => this.setState({phone: event.target.value})}/>}
                      </FormattedMessage>
                    </Col>
                  </Row>
                </Input.Group>
              </Col>
            </Row>
            { showPassword &&
            <Row gutter={16}>
              <Col style={{ marginTop: "5px" }} xs={24} sm={24} md={24} lg={12} xl={12}>
                <FormattedMessage id="forms.email">
                { msg => <Input type="email"  size="large" placeholder={msg} disabled={this.state.buttdis} value={this.state.email} onChange={event => this.setState({email: event.target.value.toLowerCase()})}/>}
                </FormattedMessage>
              </Col>
            </Row>
            }
            { showPassword &&
            <Row gutter={16}>
              <Col style={{ marginTop: "5px" }} xs={24} sm={24} md={24} lg={12} xl={12}>
                <FormattedMessage id="forms.password">
                { msg => <Input.Password  type="password"  size="large" placeholder={msg} disabled={this.state.buttdis} value={this.state.password} onChange={event => this.setState({password: event.target.value})}/>}
                </FormattedMessage>
              </Col>
              <Col style={{ marginTop: "5px" }} xs={24} sm={24} md={24} lg={12} xl={12}>
                <FormattedMessage id="forms.passwordConfirm">
                { msg => <Input.Password type="password" size="large" type="password" placeholder={msg} disabled={this.state.buttdis} value={this.state.password2} onChange={event => this.setState({password2: event.target.value})}/>}
                </FormattedMessage>
              </Col>
            </Row>
            }
              <div  style={{ marginTop: "10px" }}>
                <Checkbox checked={this.state.checkbox} disabled={this.state.buttdis} onChange={event => this.setState({checkbox: !this.state.checkbox})}></Checkbox>&nbsp;&nbsp;
                  <label><IntlMessages id="page.signUpTC1" /></label><span style={{cursor:'pointer'}} onClick={this.acceptTC} ><a><IntlMessages id="page.signUpTC2" /></a></span><label><IntlMessages id="page.signUpTC3" /></label><span style={{cursor:'pointer'}} onClick={this.acceptTC} ><a><IntlMessages id="page.signUpTC4" /></a></span>.

              </div>
              <div style={{ marginTop: "15px" }}>
                <Button
                  type="primary"
                  size="large"
                  onClick={event => this.handleRegister('password')}
                  loading={this.state.buttdis}
                  block>
                  <Avatar size={30} src={<MailOutlined />} />&nbsp;&nbsp;<IntlMessages id="page.signUpButton" />
                </Button>
              </div>
              <div style={{ marginTop: "15px" }}>
                <Button
                  size="large"
                  onClick={event => this.handleRegister('google')}
                  loading={this.state.buttdis}
                  block>
                  <Avatar size={25} src={SocialMediaIcons.google} />&nbsp;&nbsp;<IntlMessages id="page.signUpButtonGoogle" />
                </Button>
              </div>
              <div>
                <Divider style={{ marginTop: "15px", marginBottom: "5px" }}/>
                <label>
                  <IntlMessages id="page.signUpAlreadyAccount" />&nbsp;
                </label>
                <label style={{ cursor: "pointer", color: "#4390ff" }} onClick={this.triggerSignin}>
                  <IntlMessages id="page.signUpAlreadyAccountLink" />
                </label>
              </div>
            </div>
    );
  }
}

export default injectIntl(connect(
  state => ({
    locale: state.LanguageSwitcher.language.locale,
    ipCountry: state.Auth.country,
  }),
  { logoutMember, loginMember , addUserCredentials, changeAvatar, changeUserAddress }
)(SignUp));
