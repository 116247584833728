import actions from "./actions";
import { topbarTheme, sidebarTheme, layoutTheme} from '../../settings/index';



const initState = { isLoggedIn: false,
                    isLoggedInMember: false,
                    profilePicture: '',
                    inputNumber: 2,
                    country: '',
                    region: '',
                    selectedType: 'All',
                    dateStart: '',
                    dateEnd: '',
                    shopMap: [],
                    logo: '',
                    user: [],
                    userAddress: '',
                    topbarTheme: topbarTheme,
                    sidebarTheme: sidebarTheme,
                    layoutTheme: layoutTheme
 };

export default function authReducer(
  state = initState, action  )
  {switch (action.type) {
    case actions.LOGIN_SUCCESS:
      return {
        ...state,
        isLoggedIn: true,
      };

    case actions.LOGIN_SUCCESS_MEMBER:
      return {
        ...state,
        isLoggedInMember: true,
      };

    case actions.ADD_USER_CREDENTIALS:
     return {
      ...state,
      user: state.user.concat([action.payload])
     }
    case actions.CHANGE_AVATAR:
     return {
      ...state,
      profilePicture:  action.url
      }
    case actions.CHANGE_LOGO:
      return {
      ...state,
      logo:  action.url
      }
    case actions.CHANGE_THEME:
      return {
        ...state,
        [action.attribute]: action.theme
      }
    case actions.FETCH_USER:
      return {
       ...state,
      }
    case actions.SET_COUNTRY:
      return {
       ...state,
      country: action.country
      }
    case actions.CHANGE_COUNTRY:
      return {
       ...state,
      country: action.country
      }
    case actions.USER_ADDRESS:
      return {
       ...state,
      userAddress: action.userAddress
      }
    case actions.CHANGE_SELECTEDTYPE:
      return {
       ...state,
      selectedType: action.selectedType
      }
    case actions.SET_STARTDATE:
      return {
       ...state,
      dateStart: action.dateStart
      }
    case actions.SET_ENDDATE:
      return {
       ...state,
      dateEnd: action.dateEnd
      }
    case actions.SET_REGION:
      return {
       ...state,
      region: action.region
      }
    case actions.SET_SHOPMAP:
      return {
        ...state,
      shopMap: action.shopMap
      }
    case actions.LOGOUT:
      //return initState;
      return {
       ...state,
      isLoggedIn: false,
      isLoggedInMember: false,
      profilePicture: '',
      logo: '',
      user: [],
      userAddress: ''
     }
    default:
      return state;
  }
}
