import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";

import { connect } from "react-redux";
import { MailOutlined, UnlockOutlined } from '@ant-design/icons';
import { Input, Divider, Avatar, Button  } from "antd";
import authAction from "../../redux/auth/actions";
import appAction from "../../redux/app/actions";
import { FormattedMessage, injectIntl } from 'react-intl';
import IntlMessages from "../../components/utility/intlMessages";
import FirebaseHelper from "../../helpers/firebase/index";
import notification from '../../components/uielements/notification';
import notificationIntl from '../../components/uielements/notificationIntl';
import SocialMediaIcons from '../../settings/socialMedia/icons';
const { loginMember , logoutMember, addUserCredentials, changeAvatar, changeUserAddress } = authAction;
const { clearMenu } = appAction;
class SignIn extends Component {
  constructor(props){
      super(props);
      this.onLoadRecaptcha = this.onLoadRecaptcha.bind(this);
      this.verifyCallback = this.verifyCallback.bind(this);
      this.triggerSignup = this.triggerSignup.bind(this);
      this.triggerForgotPassword = this.triggerForgotPassword.bind(this);
      this.handleLogin = this.handleLogin.bind(this);
      this.state = {
        email:'',
        password:'',
        buttdispw: false,
        buttvalpw: false,
        buttdisgoogle: false,
        buttvalgoogle: false,
        socialMedia: false,
        buttdis: false,
      }
  }
  state = {
    redirectToReferrer: false
  };
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      this.props.isLoggedIn !== nextProps.isLoggedIn &&
      nextProps.isLoggedIn === true
    ) {
      this.setState({ redirectToReferrer: true });
    }
  }
  handleLogin = (provider) => {
  const { formatMessage } = this.props.intl;

  let userCred;

    if (provider == 'password') {
      const { email, password } = this.state;

      if (!(email && password)) {
  //      notification('error', 'Please fill in email and password');
        notificationIntl('error','not.emailAndPassword',formatMessage);
        return;
      }
    }
    
    (async() => {
      const { socialMedia } = this.state;

      if (provider == 'password') {
        this.setState({ buttdisgoogle: true });
        this.setState({ buttvalpw: true });
        this.setState({ buttdis: true });
        const { email, password } = this.state;
        const { locale } = this.props;
        userCred = await FirebaseHelper.firebaseLogin(email,password,locale)

          .catch(error => {
            const message = error.message;
            notification('error', message);
            this.props.logoutMember();
            this.setState({ buttdisgoogle: false });
            this.setState({ buttvalpw: false });
            this.setState({ buttdis: false });

            return;
          })

          .then(result => {

           if (result) {
             //console.log('result',result.user.emailVerified);
             if (result.user.emailVerified == false) {
               FirebaseHelper.sendEmailVerification(this.props.locale)
               .then(() => {
                  this.props.logoutMember();
                  this.props.closeLogin();
                  notificationIntl('info','action.sendEmailVerification',formatMessage);
                  this.setState({ buttdisgoogle: false });
                  this.setState({ buttvalpw: false });
                  this.setState({ buttdis: false });

                }).catch((error) => {
                  this.props.logoutMember();
                  this.props.closeLogin();
                  notificationIntl('info','action.sendEmailVerification',formatMessage);
                  this.setState({ buttdisgoogle: false });
                  this.setState({ buttvalpw: false });
                  this.setState({ buttdis: false });
                });

              return '';
            } else {

              return {  uid: result.user.uid,
                      email: result.user.email,
                      photoURL : ''
                     };
              }
           }
          });
     }
     if (provider == 'google') {
       this.setState({ buttvalgoogle: true });
       this.setState({ buttdispw: true });
       this.setState({ buttdis: true });
       this.setState({socialMedia: true });
       const { locale } = this.props;
       console.log('google');

       var prov = FirebaseHelper.googleAuthProvider();
       userCred = await FirebaseHelper.firebaseLoginProvider(prov,locale).then(function(result) {
         var user = result.user;

         return {  uid: result.user.uid,
                 email: result.user.email,
                 photoURL : result.user.photoURL };

       }).catch(function(error) {

         var errorCode = error.code;
         var errorMessage = error.message;
         notification('error', errorMessage);
         this.setState({socialMedia: false });
         this.setState({ buttvalgoogle: false });
         this.setState({ buttdispw: false });
         this.setState({ buttdis: false });
         return;

       }.bind(this));

     }

        if (userCred) {
      //  console.log(userId);
              FirebaseHelper.firebaseGetDoc('members', userCred.uid)
                  .then(doc => {

                if (doc.exists && doc.id) {

                     let user = doc.data();

                     if ( user.email !== userCred.email) {
                       FirebaseHelper.firebaseUpdateDocAttribute('members', userCred.uid, { email: userCred.email });
                       user.email = userCred.email;
                     }

                     let uid = doc.id;
                /*     let a={};
                     a.firstname = user.firstname;
                     a.lastname = user.lastname;
                     a.address = user.address;
                     a.address2 = user.address2 || '';
                     a.zip = user.zip;
                     a.city = user.city;
                     a.state = user.state;
                     a.country = user.country;
                     a.countryCode = user.countryCode;
                     a.phone = user.phone || '';
                     a.phonePre = user.phonePre || '';
                     a.email = userCred.email;*/

                     this.props.changeAvatar(userCred.photoURL);
                     this.props.changeUserAddress(user);
                     this.props.addUserCredentials('member');              //0
                     this.props.addUserCredentials(user.firstname);        //1
                     this.props.addUserCredentials(user.lastname);         //2
                     this.props.addUserCredentials('member');            //3
                     this.props.addUserCredentials('member');              //4
                     this.props.addUserCredentials(uid);                   //5
                     this.props.loginMember();
                     if (this.props.prodcount > 0){
                       this.props.history.push('/checkout');
                     }
                     return;
                  //  });

                }
                else if (this.state.socialMedia) {
                  //  notification('error', 'Please sign up before using social media sign in.');
                    notificationIntl('error','not.signUpBeforeIn',formatMessage);
                    this.props.logoutMember();
                    this.setState({socialMedia: false });
                    this.setState({ buttvalgoogle: false });
                    this.setState({ buttdispw: false });
                    this.setState({ buttdis: false });
                    return;
                } else {
                    notificationIntl('error','not.notMember',formatMessage);
                    this.props.logoutMember();
                    this.setState({ buttdisgoogle: false });
                    this.setState({ buttvalpw: false });
                    this.setState({ buttdis: false });

                }


                })
                .catch(err => {
                     console.log('catch 3',err);
                     notification('error', err);
                     this.setState({ buttdisgoogle: false });
                     this.setState({ buttvalpw: false });
                     this.setState({ buttvalgoogle: false });
                     this.setState({ buttdispw: false });
                     this.setState({ buttdis: false });

                     return;
                   });

              } else {
                // notification('error', 'No user id found.');
                this.setState({ buttdisgoogle: false });
                this.setState({ buttvalpw: false });
                this.setState({ buttvalgoogle: false });
                this.setState({ buttdispw: false });
                this.setState({ buttdis: false });
                return;

              }
      })();
      //  });
    };



  componentDidMount() {

    if (this.captchaDemo) {
        console.log("started, just a second...")
        this.captchaDemo.reset();
        this.captchaDemo.execute();
    }
  }

  onLoadRecaptcha() {
    if (this.captchaDemo) {
        this.captchaDemo.reset();
        this.captchaDemo.execute();
    }
  }

  verifyCallback(recaptchaToken) {
    // Here you will get the final recaptchaToken!!!
    console.log(recaptchaToken, "<= your recaptcha token")
  }


  triggerSignup() {
    this.props.handleSignup();
  }
  triggerForgotPassword() {
    this.props.handleForgotPassword();
  }

  render() {
    const from = { pathname: "/dashboard" };
    const { redirectToReferrer } = this.state;
    const { logoDark } = this.props;

    if (redirectToReferrer) {
      return <Redirect to={from} />;
    }

/*
    <span
      style={{ cursor: "pointer"}}
      onClick={event => this.handleLogin('facebook')}
    >
    <Avatar size={37} src={SocialMediaIcons.facebook} />
    </span>
    <span
      style={{ cursor: "pointer"}}
      onClick={event => this.handleLogin('twitter')}
    >
    <Avatar size={37} src={SocialMediaIcons.twitter} />
    </span>

    <Divider style={{ marginTop: "15px", marginBottom: "5px" }}/>
    <div style={{ marginTop: "0px", marginBottom: "15px", display: "flex", flexDirection: "row", justifyContent: "space-around", alignItems: 'center' }}>
      <span><IntlMessages id="forms.socialMedia" /></span>
    </div>
    <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-around", alignItems: 'center' }}>
      <span
        style={{ cursor: "pointer"}}
        onClick={event => this.handleLogin('google')}
      >
      <Avatar size={35} src={SocialMediaIcons.google} />
      </span>
      <span
        style={{ cursor: "pointer"}}
        onClick={event => this.handleLogin('apple')}
      >
      <Avatar size={37} src={SocialMediaIcons.apple} />
      </span>
    </div>




*/

    return (
      <div>
        <div>
            <img height={40} src={logoDark} />
        </div>
        <Divider style={{ marginTop: "15px", marginBottom: "15px" }}/>
          <div>
            <FormattedMessage id="forms.email">
            { msg => <Input
              type="email"
              value={this.state.email}
              size="large"
              placeholder={msg}
              disabled={this.state.buttdis}
              prefix={<MailOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
              onChange={event => this.setState({email: event.target.value.toLowerCase()})}
              onPressEnter={event => this.handleLogin('password')}
              />}
            </FormattedMessage>
          </div>
          <div style={{ marginTop: "5px" }}>
            <FormattedMessage id="forms.password">
            { msg => <Input.Password
              type="password"
              value={this.state.password}
              size="large" type="password"
              placeholder={msg}
              disabled={this.state.buttdis}
              prefix={<UnlockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
              onChange={event => this.setState({password: event.target.value})}
              onPressEnter={event => this.handleLogin('password')}
              />}
            </FormattedMessage>
          </div>
          <div style={{ marginTop: "15px" }}>
            <Button
              size="large"
              type="primary"
              onClick={event => this.handleLogin('password')}
              loading={this.state.buttvalpw} 
              disabled={this.state.buttdispw} 
              block>
              <IntlMessages id="page.signInButton" />
            </Button>
          </div>
          <div style={{ marginTop: "15px" }}>
            <Button

              size="large"
              onClick={event => this.handleLogin('google')}
              loading={this.state.buttvalgoogle} 
              disabled={this.state.buttdisgoogle} 
              block>
              <Avatar size={25} src={SocialMediaIcons.google} />&nbsp;&nbsp;<IntlMessages id="page.signInButtonGoogle" />
            </Button>
          </div>


            <Divider style={{ marginTop: "15px", marginBottom: "5px" }}/>
          <div >
            <label>
              <IntlMessages id="page.signInCreateAccount" />&nbsp;
            </label>
            <label style={{ cursor: "pointer", color: "#4390ff" }} onClick={this.triggerSignup}>
              <IntlMessages id="page.signInCreateAccountLink" />
            </label>
          </div>
          <div style={{ marginTop: "5px" }}>
            <label>
              <IntlMessages id="page.signInForgotPass" />&nbsp;
            </label>
            <label style={{ cursor: "pointer", color: "#4390ff" }} onClick={this.triggerForgotPassword}>
              <IntlMessages id="page.signInForgotPassLink" />
            </label>
          </div>
        </div>
    );
  }
}

export default injectIntl(connect(
  state => ({
    ...state.Auth,
    locale: state.LanguageSwitcher.language.locale,
    prodcount: state.Ecommerce.productQuantity.length,
  }),
  { loginMember , logoutMember, addUserCredentials, changeAvatar, changeUserAddress }
)(SignIn));
