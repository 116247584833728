import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import actions from '../../redux/languageSwitcher/actions';
import config from './config';
import { Cascader, Tooltip } from 'antd';
import Cookies from 'universal-cookie';
const { changeLanguage } = actions;
const cookies = new Cookies();

class LanguageSwitcher extends Component {
  constructor(props) {
    super(props);
      this.state = {
         text: 'Unselect',
       }
  }
   onChange = (value, selectedOptions) => {
     console.log('value[0]', value[0]);
     this.props.changeLanguage(value[0]);
     cookies.set('language', value[0], { path: '/' });
     };



  render() {
    const { changeLanguage, locale, color, size, view } = this.props;
  //  const fcolor=color;




    /*
    <div className="themeSwitchBlock">
      <h4>
        <IntlMessages id="languageSwitcher.label" />
      </h4>
      <div className="themeSwitchBtnWrapper">
        {config.options.map(option => {
          const { languageId, icon } = option;
          const customClass =
            languageId === language.languageId
              ? 'selectedTheme languageSwitch'
              : 'languageSwitch';


          return (
            <button width="20px"
              type="button"
              className={customClass}
              key={languageId}
              onClick={() => {
                changeLanguage(languageId);
              }}
            >
              <img src={process.env.PUBLIC_URL + icon} alt="flag" />
            </button>
          );
        })}
      </div>
    </div>
    */
    console.log('view',view);
    return (
    <span>
      { view === 'DesktopView' ?
      <span>
        <FormattedMessage id="menu.language">
        { msg => <Tooltip placement="bottom" title={msg}>
          <Cascader options={config.options} onChange={this.onChange}>
            <span style={{ textDecoration: "underline", fontWeight: "400", color: color, fontSize: size }}>{locale}</span>
          </Cascader>
        </Tooltip>}
       </FormattedMessage>
      </span>
      :
      <span>
          <Cascader options={config.options} onChange={this.onChange}>
            <span style={{ textDecoration: "underline", fontWeight: "400", color: color, fontSize: size }}>{locale}</span>
          </Cascader>
      </span>
      }
    </span>
    );
  }
}

export default connect(
  state => ({
    ...state.LanguageSwitcher,
    locale: state.LanguageSwitcher.language.locale,
    view: state.App.view
  }),
  { changeLanguage }
)(LanguageSwitcher);
