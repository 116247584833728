import antdES from 'antd/es/locale/es_ES';
import appLocaleData from 'react-intl/locale-data/es';
import antdPicker from 'antd/es/date-picker/locale/es_ES';
import saMessages from '../locales/es_ES.json';

const EsLang = {
  messages: {
    ...saMessages
  },
  antd: antdES,
  locale: 'es-ES',
  data: appLocaleData,
  antdPicker: antdPicker
};
export default EsLang;
