import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { HashLink } from 'react-router-hash-link';
import { LoginOutlined, UserAddOutlined } from '@ant-design/icons';
import { Layout, Tooltip, Drawer, Divider, PageHeader, Modal } from "antd";
import appActions from "../../redux/app/actions";
import TopbarWrapper from "./topbar.style";
import authAction from '../../redux/auth/actions';
import Signin from '../../containers/Page/signin';
import Signup from '../../containers/Page/signup';
import ForgotPassword from '../../containers/Page/forgotPassword';
//import IntlMessages from '../../components/utility/intlMessages';
import { FormattedMessage } from 'react-intl';
import TopbarAddtoCart from './topbarAddtoCart';
import TopbarMenu from './topbarMenu';
import LanguageSwitcher from '../../containers/LanguageSwitcher';
import logo from '../../image/logo.png';
import logoDark from '../../image/logoDark.png';
//import Flags from "../../settings/countries/flags";
const { Header } = Layout;
const { view } = appActions;
const { logout } = authAction;

class TopbarShop extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email:'fritz@aa.aa',
      password:'212121',
      buttdis:false,
      modalLogin:false,
      modalSignup:false,
      modalForgotPassword:false,
      logo: logo,
      logoDark: logoDark,
      visibleMenu: false,
    }
  }

  handleLogin = () => {
    this.setState({modalLogin:true})
    this.setState({modalSignup:false})
    this.setState({modalForgotPassword:false})
  }

  handleSignup = () => {
    this.setState({modalLogin:false})
    this.setState({modalSignup:true})
    this.setState({modalForgotPassword:false})
  }

  handleForgotPassword = () => {
    this.setState({modalLogin:false})
    this.setState({modalSignup:false})
    this.setState({modalForgotPassword:true})
  }

  closeLogin = () => {
    this.setState({modalLogin:false})
  }

  closeSignup = () => {
    this.setState({modalSignup:false})
  }

  closeForgotPassword = () => {
    this.setState({modalForgotPassword:false})
  }

  showMenu = () => {
    this.setState({
      visibleMenu: true,
    });
  };

  closeMenu = () => {
    this.setState({
      visibleMenu: false,
    });
  };


/*
  toggleSignup = () => {
    this.setState({modalSignup:false})
  }

  toggleForgotPassword = () => {
    this.setState({modalForgotPassword:false})
  }
*/
  render() {
    const { history, view ,customizedTheme ,locale ,country, logo, color, url, isLoggedInMember, intl, eventId } = this.props; //url
    const { modalVisible , modalLogin, modalSignup , modalForgotPassword } = this.state;
    const styling = {
      background: customizedTheme.backgroundColor,
      position: "fixed",
      zIndex: 1,
      width: "100%",
      height: 70

    };
    let event = '';
    if (eventId) {
      event = '#' + eventId;
    }
/*
&nbsp;&nbsp;

  <img height='25px' src={Flags[country.toLowerCase()]} />

*/

    return (
      <TopbarWrapper>
        <Header
          style={styling}
          className={"Topbar"}

        >
          <div className="isoLeft">
            { view === "MobileView" &&
              <TopbarMenu handleLogin={this.handleLogin} handleSignup={this.handleSignup} locale={locale}/>
            }
            { view === "MobileView" ?
              <HashLink to={'../' + event}>
                <img alt='logo' height={40} src={this.state.logo} width="auto" style={{ marginLeft: '0px', marginBottom: '4px'}} />
              </HashLink>
                :
              <HashLink to={'../' + event}>
                <img alt='logo' height={50} src={this.state.logo} />
              </HashLink>
            }



          </div>


          <ul className="isoRight" style={{  margin: "0px", padding: "0px" }}>
            { view !== "MobileView" &&
              <li>

                <LanguageSwitcher
                  color={customizedTheme.textColor}
                  size="20px"
                />
              </li>
            }
            <li
              onClick={() => this.setState({ selectedItem: "addToCart" })}
              className="isoCart"
            >
              <Tooltip placement="bottom" title="shopping cart">
                <TopbarAddtoCart
                  locale={locale}
                  url={url}
                  showLogin={this.handleLogin}
                />
              </Tooltip>
            </li>
            <li onClick={this.handleSignup}  >
            { view == 'DesktopView' ? (
              <FormattedMessage id="menu.signup">
                { msg => <Tooltip placement="bottom" title={msg}>
                <UserAddOutlined style={{ color: customizedTheme.textColor, fontSize: "25px" }} />
               </Tooltip>}
             </FormattedMessage>
             ):(
              <UserAddOutlined style={{ color: customizedTheme.textColor, fontSize: "25px" }} />
             )}
            </li>
            <li onClick={this.handleLogin}  >
            { view == 'DesktopView' ? (
              <FormattedMessage id="menu.login">
                { msg => <Tooltip placement="bottom" title={msg}>
                <LoginOutlined style={{ color: customizedTheme.textColor, fontSize: "25px" }} />
               </Tooltip>}
              </FormattedMessage>
            ):(
              <LoginOutlined style={{ color: customizedTheme.textColor, fontSize: "25px" }} />
            )}
            </li>

          </ul>

          <Drawer
              width="70%"
              placement="left"
              closable={false}
              onClose={this.closeMenu}
              visible={this.state.visibleMenu}

            >
            <PageHeader onBack={this.closeMenu} title="Menu"  style={{  margin: "0px", padding: "0px" }}/>
            <Divider />
              <span style={{ fontSize: "16px" }}>Language: </span><LanguageSwitcher size="16px"/>
          </Drawer>

        </Header>



        {
          modalLogin &&
          <Modal
            visible={modalLogin}
            closable={false}
            width={ view == 'MobileView' ? '100%': '350px'}
            onClose={this.closeLogin}
            onCancel={this.closeLogin}
            footer={null}
          >
          <Signin intl={intl} history={history} closeLogin={this.closeLogin} handleSignup={this.handleSignup} handleForgotPassword={this.handleForgotPassword} logoDark={this.state.logoDark}/>
          </Modal>
        }
        {
          modalSignup &&
          <Modal
            visible={modalSignup}
            closable={false}
            width={ view == 'MobileView' ? '100%': '550px'}
            onClose={this.closeSignup}
            onCancel={this.closeSignup}
            footer={null}
          >
          <Signup showTC={this.props.showTC} closeSignup={this.closeSignup} history={history} handleLogin={this.handleLogin}  handleForgotPassword={this.handleForgotPassword} toggleLogin={this.toggleLogin} logoDark={this.state.logoDark}/>
          </Modal>
        }
        {
          modalForgotPassword &&
          <Modal
            visible={modalForgotPassword}
            closable={false}
            width={ view == 'MobileView' ? '100%': '350px'}
            onClose={this.closeForgotPassword}
            onCancel={this.closeForgotPassword}
            footer={null}
          >
          <ForgotPassword history={history} handleLogin={this.handleLogin} logoDark={this.state.logoDark} closeForgotPassword={this.closeForgotPassword}/>
          </Modal>
        }



      </TopbarWrapper>
    );
  }
}

export default connect(
  state => ({
    ...state.App,
    eventId: state.Tickets.eventId,
    locale: state.LanguageSwitcher.language.locale,
    country: state.Auth.country || '',
    customizedTheme: state.Auth.topbarTheme,
    isLoggedInMember: state.Auth.isLoggedInMember,
  }),
)(TopbarShop);
