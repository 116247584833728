//import React from 'react';
//import ReactDOM from 'react-dom';
import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
//import createHistory from 'history/createBrowserHistory';
//import createHistory from "history"
import { routerReducer, routerMiddleware } from 'react-router-redux';
import thunk from 'redux-thunk';
import { reduxFirestore , firestoreReducer, getFirestore } from 'redux-firestore';
import { reactReduxFirebase , getFirebase , firebaseStateReducer} from 'react-redux-firebase';
import reducers from '../redux/reducers';
import { fbConfig } from '../helpers/firebase/index';

const history = require("history").createBrowserHistory();
const routeMiddleware = routerMiddleware(history);
const middlewares = [thunk.withExtraArgument({ getFirebase , getFirestore }), routeMiddleware];
/*
const composeEnhancers =
  typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
        // Specify extension’s options like name, actionsBlacklist, actionsCreators, serialize...


      })
    : compose;
*/
const store = createStore(
  combineReducers({
    ...reducers,
    router: routerReducer,
    firebase: firebaseStateReducer,
    firestore: firestoreReducer
  }),
  compose(applyMiddleware(...middlewares),
                 reduxFirestore(fbConfig),
                 reactReduxFirebase(fbConfig, {
                   userProfile: 'user',
                   useFirestoreForProfile: true,
                   enableLogging: false,
                   attachAuthIsReady: true
                 },))
  //composeEnhancers
);

/*store.subscribe(() => {
    //this is just a function that saves state to localStorage
    saveState(store.getState());
});
*/

export { store, history };
