import Enlang from './entries/en_US';
import Itlang from './entries/it_IT';
import Eslang from './entries/es_ES';
import Frlang from './entries/fr_FR';
import Delang from './entries/de_DE';
import { addLocaleData } from 'react-intl';

const AppLocale = {
  en: Enlang,
  it: Itlang,
  es: Eslang,
  fr: Frlang,
  de: Delang
};
addLocaleData(AppLocale.en.data);
addLocaleData(AppLocale.it.data);
addLocaleData(AppLocale.es.data);
addLocaleData(AppLocale.fr.data);
addLocaleData(AppLocale.de.data);
export default AppLocale;
