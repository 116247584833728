
import actions, { getView } from "./actions";

const initState = {
  view: getView(window.innerWidth),
  height: window.innerHeight,
  width: window.innerWidth
};

export default function appReducer(state = initState, action) {
  switch (action.type) {
    case actions.TOGGLE_ALL:
      if (state.view !== action.view || action.height !== state.height) {
        const height = action.height ? action.height : state.height;
        const width = action.width ? action.width : state.width;
        return {
          ...state,
          view: action.view,
          height,
          width
        };
      }
      break;
    default:
      return state;
  }
  return state;
}
