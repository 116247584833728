


const labels = {
                  AUD: 'AU$',
                  CAD: 'CDN$',
                  CHF: 'CHF',
                  DKK: 'DKK',
                  EUR: '€',
                  GBP: '£',
                  NOK: 'NOK',
                  NZD: 'NZ$',
                  SEK: 'SEK',
                  USD: '$',
                };

export default labels;
