import React, { Component } from 'react';
import { connect } from 'react-redux';
import { MailOutlined } from '@ant-design/icons';
import { Input, Divider, Button } from "antd";
import IntlMessages from '../../components/utility/intlMessages';
import { FormattedMessage,  injectIntl } from 'react-intl';
import FirebaseHelper from "../../helpers/firebase/index";
import notification from '../../components/uielements/notification';
import notificationIntl from '../../components/uielements/notificationIntl';

class ForgotPW extends Component {
  constructor(props){
      super(props);
      this.triggerSignin = this.triggerSignin.bind(this);
      this.handlePW = this.handlePW.bind(this);
      this.state = {
        email:'',

      }
  }

  handlePW() {
      const { email } = this.state;
      const { formatMessage } = this.props.intl;
      const { locale } = this.props;

      function validateEmail(email) {
        const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
       }


      if (validateEmail(email) == false) {
        //notification('error', 'Please fill out all required fields.');
        notificationIntl('error','not.validEmail',formatMessage);
        return;
        }
        FirebaseHelper.sendPasswordResetEmail(email,locale)
        .then(() => {
          this.props.closeForgotPassword();
          notificationIntl('success','not.successEmailsent',formatMessage);
          //notification('success', 'Email with further instructions sent');
          return;
        })
        .catch((error) => {
          var errorCode = error.code;
          var errorMessage = error.message;
          notification('error', errorMessage);
        });
  }

  triggerSignin() {
    this.props.handleLogin();
  }

  render() {
    const { logoDark } = this.props;
    return (
      <div>
        <div>
          <img height={40} src={logoDark} />
        </div>
        <Divider  style={{ marginTop: "15px", marginBottom: "15px" }}/>
        <div>
          <p>
            <IntlMessages id="page.forgetPassDescription" />
          </p>
        </div>

          <div style={{ marginTop: "5px" }}>
            <Input value={this.state.email} size="large" placeholder="Email" prefix={<MailOutlined style={{ color: 'rgba(0,0,0,.25)' }} />} onChange={event => this.setState({email: event.target.value})}/>
          </div>

          <div style={{ marginTop: "15px" }}>
            <Button type="primary" size="large" onClick={this.handlePW} block>
              <IntlMessages id="page.sendRequest" />
            </Button>
          </div>
          <Divider  style={{ marginTop: "15px", marginBottom: "5px" }}/>
          <div>
            <label>
              <IntlMessages id="page.forgetPassToSignIn" />&nbsp;
            </label>
            <label style={{ cursor: "pointer", color: "#4390ff" }} onClick={this.triggerSignin}>
              <IntlMessages id="page.forgetPassToSignInLink" />
            </label>
          </div>
        </div>
    );
  }
}

export default injectIntl(connect(
  state => ({
    locale: state.LanguageSwitcher.language.locale
  }),
  { }
)(ForgotPW));
