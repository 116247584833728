import antdIT from 'antd/es/locale/it_IT';
import appLocaleData from 'react-intl/locale-data/it';
import antdPicker from 'antd/es/date-picker/locale/it_IT';
import saMessages from '../locales/it_IT.json';

const ItLang = {
  messages: {
    ...saMessages
  },
  antd: antdIT,
  locale: 'it-IT',
  data: appLocaleData,
  antdPicker: antdPicker
};
export default ItLang;
